import React from 'react';
import FrontPage from './screens (19) /FrontPage';
import './styles/App.css';
import Placeholder from './screens (19) /Placeholder';

const App = () => {
  return (
    <div>
      {/* <FrontPage /> */}
      <Placeholder />
    </div>
  );
};

export default App;
