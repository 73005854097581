import React from 'react';
import './../styles/styles.css';
import '../styles/Placeholder.css';

const Placeholder = () => {
  return (
    <div>
      <div class='ternary-system'>
        <div class='sun primary'></div>
        <div class='sun secondary'></div>
        <div class='sun ternary'></div>
      </div>
      <div class='sand'>
        <div class='pendulums'>
          <div class='pendulum'>
            <div class='bar'></div>
            <div class='motion'>
              <div class='string'></div>
              <div class='weight'></div>
            </div>
          </div>
          <div class='pendulum shadow'>
            <div class='bar'></div>
            <div class='motion'>
              <div class='string'></div>
              <div class='weight'></div>
            </div>
          </div>
        </div>
      </div>
      <div class='pyramid'></div>
      <div class='text'>
        <h1 class='title'>
          Off <strong>Doing</strong>
          <em>stuff</em>
        </h1>
        <p></p>
      </div>
    </div>
  );
};

export default Placeholder;
