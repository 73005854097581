import React from "react";
import axios from 'axios';
import "./Contact.css";

class Contact extends React.Component { 
  handleSubmit(e){
    e.preventDefault();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    axios({
      method: "POST",
      url: "https://mkirv.herokuapp.com/send",
      data: {
        name: name,
        email: email,
        message: message
      }
    }).then(response => {
      if (response.data.msg === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.msg === "fail") {
        alert("Message failed to send.");
      }
    });
  };

  resetForm(){
    document.getElementById('contact-form').reset();
}

 render() {
  return (
    <div id="Contact">
      <div className="container-contact100">
        <div className="wrap-contact100">
          <form 
          className="contact100-form validate-form"
          id="contact-form" 
          onSubmit={this.handleSubmit.bind(this)} 
          method="POST">
            <span className="contact100-form-title">Say Hello!</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Name is required"
            >
              <span className="label-input100">Your Name</span>
              <input
                className="input100"
                type="text"
                name="name"
                id='name'
                placeholder="Enter your name"
              />
              <span className="focus-input100"></span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                id="email" 
                placeholder="Enter your email addess"
              />
              <span className="focus-input100"></span>
            </div>
            <div
              className="wrap-input100 validate-input"
              data-validate="Message is required"
            >
              <span className="label-input100">Message</span>
              <textarea
                className="input100"
                name="message"
                id="message"
                placeholder="Your message here..."
              ></textarea>
              <span className="focus-input100"></span>
            </div>
            <div className="container-contact100-form-btn">
              <div className="wrap-contact100-form-btn">
                <div className="contact100-form-bgbtn"></div>
                <button className="contact100-form-btn"
                type="submit" >
                  <span>
                    Submit
                    <i
                      className="fa fa-long-arrow-right m-l-7"
                      aria-hidden="true"
                    ></i>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
}

export default Contact;
